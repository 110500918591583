<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
            <h4 class="mb-sm-0">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <TotalSaldo/>
        </div>
      </div>
      <ShareOverview />
      <ShareDistibutionOverview />
    </div>
  </div>
</template>

<script>

import globalService from '../../services/global-service';
import ShareDistibutionOverview from '../../userViews/shareDistribution/ShareDistibutionOverview.vue';
import ShareOverview from '../../userViews/share/ShareOverview.vue';
import TotalSaldo from './TotalSaldo.vue';

export default {
  components: {
    ShareDistibutionOverview,
    ShareOverview,
    TotalSaldo
},

  created() {
  },

  data() {
    return {
      share_period: null,
      load: false,
      load_period: false,
    };
  },

  methods: {

    toDate(a) {
      return globalService.dateFormat(a);
    },

    toIDr(a) {
      return globalService.toIdr(a);
    }
  }
}
</script>

<style>
</style>